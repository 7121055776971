<template style="margin-bottom: 160px; margin-top: 10px;">
  <div>
    <vx-card :title="card.title + (card.sub_title ? ' | ' : '') + 'Serial Number ' + card.sub_title"
      style="margin-bottom: 160px; margin-top: 10px;">
      <vs-tabs :color="colorx" v-model="tabs">
        <vs-tab @click="card.sub_title = 'Registration'" label="SN Registration">
          <div class="con-tab-ejemplo">
            <sn_register @initLength="initLength" />
          </div>
        </vs-tab>
        <!-- <vs-tab @click="card.sub_title = 'Allocation'" label="SN Allocation">
          <div class="con-tab-ejemplo">
            <sn_allocation :taxNumberLength="taxNumberLength" />
          </div>
        </vs-tab> -->
        <vs-tab @click="card.sub_title = 'Generation'" label="Tax Generation">
          <div class="con-tab-ejemplo">
            <sn_generate :taxNumberLength="taxNumberLength" />
          </div>
        </vs-tab>
        <vs-tab @click="card.sub_title = 'Export CSV'" label="Export CSV">
          <div class="con-tab-ejemplo">
            <sn_export :taxNumberLength="taxNumberLength" />
          </div>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </div>
</template>
<script>
import sn_register from "./sn_register.vue";
// import sn_register from "./sn_register2.vue";
// import sn_allocation from "./sn_allocation.vue";
// import sn_allocation from "./sn_allocation2.vue";
import sn_generate from "./generate";
import sn_export from "./export/index.vue";
// import sn_export from "./export.vue";

export default {
  components: {
    sn_register,
    //  sn_allocation,
    sn_generate, sn_export,
  },

  data() {
    return {
      card: {
        title: "Tax Invoice",
        sub_title: "Registration"
      },
      colorx: "success",
      tabs: 0,
      taxNumberLength: 0,
    };
  },
  watch: {},
  mounted() {
  },
  methods: {
    initLength(length) {
      this.taxNumberLength = length
    }
  },
};
</script>
<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}

.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
.core-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}

.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}

.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 50%;
  text-align: justify;
  word-wrap: break-word;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}

.red {
  background: red;
  background-color: red;
}

.yellow {
  background: yellow;
  background-color: yellow;
}
</style>