<template>
  <div ref="outside">
    <vs-table :search="!hideSearch" :stripe="tablep && tablep.stripe ? tablep.stripe : false"
      :max-items="tablep && tablep.length ? tablep.length : 5" :sst="tablep && tablep.sst ? tablep.sst : false"
      @search="handleSearch" @sort="handleSort" :current-page="2" :total="itemRefs.length" :data="itemRefs">
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div class="
          p-3
          border border-solid
          d-theme-border-grey-light
          rounded-full
          d-theme-dark-bg
          cursor-pointer
          flex
          items-center
          justify-between
          font-medium
        ">
            <span class="mr-2">{{ tablep.start }} - {{ tablep.end }} of
              {{ tablep.total }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item v-for="item in tablep.limits" :key="item" @click="handleChangelength(item)">
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <!-- <vs-th></vs-th> -->
        <vs-th v-for="(head, index) in this.setHeadTable()" :key="index"
          :sort-key="head.field == 'checkbox' ? '' : head.field">
          <div v-if="head.field == 'checkbox'">
            <formInputs v-if="modelItemRefInput.inputs[index].components.head == 'checkbox'"
              :components="modelItemRefInput.inputs[index].components" @oninputitem="oninputitem"
              @datepickerOpened="datepickerOpened" @datepickerClosed="datepickerClosed" />
            <div v-else>
              {{ head.title }}
            </div>
          </div>

          <div v-else>
            {{ head.title }}
          </div>
        </vs-th>
        <vs-th v-show="itemRefs[0] ? itemRefs[0].action : false"> </vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :key="index" v-for="(itemRef, index) in data" :state="itemRef.state">
          <vs-td :key="fi" :data="input.components.value" v-for="(input, fi) in itemRef.inputs"
            style="overflow: visible">
            <formInputs :components="input.components" @oninputitem="oninputitem" @click="onClick"
              @datepickerOpened="datepickerOpened" @datepickerClosed="datepickerClosed" />
            <template v-if="input.components.editable" slot="edit">
              <div class="w-full flex items-center space-x-2 mr-2">
                <!-- <vs-input class="w-full" v-model="input.components.value"/> -->
                <!-- <formInputs :components="{...input.components, type: 'input'}"/> -->
                <formInputs :components="input.components" :edit="true" />
                <vs-button size="small" icon-pack="feather" icon="icon-edit"
                  @click="(e) => { e.preventDefault(); input.components.editCallback(itemRef) }" />
              </div>
            </template>
          </vs-td>
          <vs-td v-if="index == 0" v-show="itemRef.action">
            <vx-tooltip text="Add Reference">
              <vs-button size="small" color="green" icon-pack="feather" icon="icon-plus" @click="handleAddItemRef()" />
            </vx-tooltip>
          </vs-td>
          <vs-td v-else v-show="itemRef.action">
            <vx-tooltip text="Delete Reference">
              <vs-button size="small" color="red" icon-pack="feather" icon="icon-x"
                @click="handleRemoveItemRef(index)" />
            </vx-tooltip>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination style="padding-top: 5px" :total="tablep.totalPage" v-model="setPage" />
  </div>
</template>


<script>
import moment from "moment";
import formInputs from "./form_inputs.vue";

export default {
  components: {
    formInputs,
  },
  props: ["itemRefs", "modelItemRefInput", "tablep", "model", "hideSearch"],
  mounted() {
    // console.log("hide", this.hideSearch)
  },
  data() {
    return {
      table: this.tableDefaultState(),
    };
  },
  watch: {
    watchedProperties: function () {
      // this.getData();
      this.setHeadTable();
    },
  },
  computed: {
    // watchedProperties() {
    //   return `${this.territories}||${this.docRefType}||${this.search}||${this.supplier}||${this.filterDate.startDate}||${this.filterDate.endDate}||${this.filterPostingDate.startDate}||${this.filterPostingDate.endDate}`;
    // },
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        heads: [
          { title: "Reference", field: "sales_office_code" },
          { title: "Start Date", field: "sales_office_desc" },
          { title: "End Date", field: "vendor_code" },
          { title: "Prefix", field: "vendor_name" },
          { title: "Start Number", field: "purchase_org_code" },
          { title: "End Number", field: "purchase_org_name" },
          { title: "Total SN", field: "debit_note_number" },
          { title: "Total SN Allocated", field: "doc_type" },
          { title: "Total SN Unallocated", field: "doc_type" },
          { title: "Total SN Used", field: "doc_ref_type" },
          { title: "Total SN Remaining", field: "doc_ref_type" },
        ],
      };
    },
    handleChangelength(item) {
      this.$emit("change-length", item);
    },
    oninputitem(data, type) {
      this.$emit("input-itemref", data, type);
    },
    handleChangePage(page) {
      this.$emit("handleChangePage", page);
      // this.table.page = page;
    },
    handleClose() {
      this.getData();
      this.$emit("close");
    },
    handleAddItemRef() {
      this.$emit("update-itemref", this.tmpItemRefsFunc(), "add");
    },
    datepickerOpened(components) {
      this.$emit("date-pickerOpened", components);
    },
    datepickerClosed(components) {
      this.$emit("date-pickerClosed", components);
    },
    tmpItemRefsFunc() {
      const itemRef = JSON.parse(JSON.stringify(this.modelItemRefInput));
      return itemRef;
    },
    setHeadTable() {
      const items = this.tmpItemRefsFunc().inputs;
      var heads = [];
      // console.log("heads", items);
      if (items) {
        items.forEach((element) => {
          heads.push({
            title: element.components.title,
            field: element.components.name,
          });
        });
      }
      return heads;
    },
    handleSearch(search) {
      this.$emit("search-itemref", search);
    },
    handleRemoveItemRef(index) {
      this.$emit("update-itemref", index, "remove");
    },
    handleSort(i) {
      console.log("handlerSort", i)
      if (i) this.$emit("handleSort", i);
    },
    dateFormat(val) {
      return moment(val).format("DD MMM YYYY");
    },
    onClick(comp) {
      this.$emit("click", comp);
    },
    getInputs() {
      var inputs = [];
      const today = new Date();
      var startDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDay()
      );
      var endDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDay() + 1
      );
      inputs.push({
        components: {
          disabled: false,
          validate: "required",
          name: "reference",
          placeholder: "Reference",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          disabled: false,
          validate: "required",
          name: "start_date",
          placeholder: "Start Date",
          type: "date",
          value: startDate,
        },
      });
      inputs.push({
        components: {
          disabled: false,
          validate: "required",
          name: "end_date",
          placeholder: "End Date",
          type: "date",
          value: endDate,
        },
      });
      inputs.push({
        components: {
          disabled: false,
          validate: "required",
          name: "prefix",
          placeholder: "Prefix",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          disabled: false,
          validate: "required",
          name: "start_number",
          placeholder: "Start Number",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          disabled: false,
          validate: "required",
          name: "end_number",
          placeholder: "End Number",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          disabled: false,
          validate: "required",
          name: "total_sn",
          placeholder: "Total SN",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          disabled: false,
          validate: "required",
          name: "total_allocation",
          placeholder: "Total Allocation",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          disabled: false,
          validate: "required",
          name: "total_remaining",
          placeholder: "Total Remaining",
          type: "input",
          value: "",
        },
      });
      return inputs;
    },
    formatPrice(val) {
      if (isNaN(val)) {
        val = 0;
      }
      val = (val / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    format(head, value) {
      if (typeof head["format"] === "function") {
        var f = head["format"];
        return f(value);
      }
      return value;
    },
  },
  beforeDestroy() {
    this.$refs.outside.click()
  },
};
</script>

<style>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
.core-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.vs-table--content {
  overflow-y: auto !important;
  overflow-x: auto !important;
}

.vs-con-table .vs-con-tbody {
  overflow-y: auto !important;
  overflow-x: auto !important;
}

.vs-table--tbody-table .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover {
  -webkit-transform: none;
  transform: none;
}
</style>
