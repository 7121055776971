<template>
    <div class="vx-row" style="margin-top: 10px;">
        <div class="vx-col md:w-1/1 w-full mb-[160px]">
            <vx-card title="Form Tax Invoice">
                <div class="vx-row mb-6 mx-4">
                    <div class="vx-col sm:w-1/5 w-full">
                        <span>Company</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <formInputs :components="this.formCompany.inputs" />
                    </div>
                </div>
                <div class="vx-row mb-6 mx-4">
                    <div class="vx-col sm:w-1/5 w-full">
                        <span>Supplier</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <formInputs :components="this.formSupplier.inputs" />
                    </div>
                </div>
                <div class="vx-row mb-6 ml-4 mr-4">
                    <div class="vx-col sm:w-1/5 w-full">
                        <span>Tax Invoice Period</span>
                    </div>
                    <div class="vx-col sm:w-1/5 w-full">
                        <formInputs :components="this.formMonth.inputs" />
                    </div>
                    <div class="vx-col sm:w-1/5 w-full">
                        <formInputs :components="this.formYear.inputs" />
                    </div>
                </div>
                <div class="vx-row mb-6 mx-4">
                    <div class="vx-col sm:w-1/5 w-full">
                        <span>Search</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <vs-input v-model="table.search" class="w-full" />
                    </div>
                </div>
                <div class="vx-row mb-6 mx-4">
                    <div class="vx-col sm:w-4/5 w-full text-center">
                        <vs-button class="mr-4" @click="handleView()" color="primary" icon-pack="feather">View
                        </vs-button>
                        <vs-button @click="handleExport()"
                            :disabled="!this.itemRefs.some((dt) => dt.inputs[1].components.value)" color="danger"
                            icon-pack="feather" type="border">Export
                        </vs-button>
                    </div>
                </div>
                <vs-row class="mb-6 ml-4 mr-4" vs-w="12">
                    <vs-col class="mb-3" vs-type="flex" vs-justify="left" vs-align="center" vs-w="12">
                        <span>Total Invoice : {{ this.itemRefs.length }} </span>
                    </vs-col>
                </vs-row>
                <vs-divider class="mb-2"></vs-divider>
                <vs-tabs :color="colorx" v-model="tabs">
                    <vs-tab @click="
                        colorx = 'success';
                    tabClick(0);
                    " label="SN Export">
                        <div class="con-tab-ejemplo">
                            <datatable :itemRefs="itemRefs" :tablep="table" :modelItemRefInput="tmpitemRef"
                                @handleChangePage="handleChangePage" />
                        </div>
                    </vs-tab>
                    <vs-tab @click="
                        colorx = 'warning';
                    tabClick(1);
                    " label="Data Export">
                        <div class="con-tab-ejemplo">
                            <export_table :hideSearch="true" type="tax-invoice-debit-note-trade" />
                        </div>
                    </vs-tab>
                </vs-tabs>
            </vx-card>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import datatable from "../datatable.vue";
import formInputs from "../form_inputs.vue";
import export_table from "./export_table.vue"


export default {
    components: {
        formInputs,
        export_table,
        datatable,

    },
    data() {
        return {
            tabs: 0,
            colorx: "success",
            formCompany: {
                id: 1,
                inputs: {
                    disabled: false,
                    validate: "required",
                    name: "company",
                    placeholder: "Company",
                    type: "input",
                    value: "",
                }
            },
            formSupplier: {
                id: 2,
                inputs: {
                    disabled: false,
                    validate: "required",
                    name: "supplier",
                    placeholder: "Type to search",
                    type: "multiselect",
                    value: [{ ID: 0, text: "All" }],
                    option: [],
                    multiple: true,
                    allowempty: false,
                    track_by: "ID",
                },
            },
            formMonth: {
                id: 3,
                inputs: this.forminputmonth(),
            },
            formYear: {
                id: 4,
                inputs: this.forminputyear(),
            },
            table: {
                start: 1,
                stripe: true,
                end: 0,
                page: 0,
                length: 10,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 0,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
            },
            itemRefs: [],
            tmpitemRef: {
                id: 2,
                action: false,
                inputs: this.getInputsHead(),
            },
        }
    },
    async mounted() {
        const data = await this.getData()
        this.loadData(data)
    },
    methods: {
        resetTable() {
            this.table.total = 0
            this.table.totalPage = 0
            this.table.totalSearch = 0
            this.table.length = 10
            this.table.start = 0
            this.table.end = 0
            this.table.stripe = false
        },
        tabClick(i) {
            this.resetTable()
            if (i == 1) {
            } else {
                this.handleView()
            }
        },
        handleChangePage(page) {
            if (this.tab == this.gen) {
                this.table.page = page;
                this.handleView()
            }
        },
        getInputsHead() {
            const inputs = [];
            inputs.push({
                components: {
                    id: 0,
                    id_input: 0,
                    disabled: true,
                    validate: "required",
                    name: "",
                    width: 40,
                    text_align: "text-center",
                    placeholder: "0",
                    title: "No",
                    type: "input",
                    value: "1",
                },
            });
            inputs.push({
                components: {
                    id: 0,
                    id_input: 1,
                    disabled: true,
                    validate: "required",
                    name: "checkbox",
                    placeholder: "Checkbox",
                    title: "checkbox",
                    head: "checkbox", //checkbox/title
                    head_val: false, //for head=checkbox
                    type: "checkbox",
                    value: false,
                },
            });
            inputs.push({
                components: {
                    id: 0,
                    id_input: 2,
                    disabled: true,
                    validate: "required",
                    name: "posting_date",
                    placeholder: "Tax Invoice Date",
                    title: "Tax Invoice Date",
                    type: "input",
                    value: "",
                },
            });
            inputs.push({
                components: {
                    id: 0,
                    id_input: 3,
                    disabled: true,
                    validate: "required",
                    name: "code",
                    placeholder: "DN Code",
                    title: "DN Code",
                    type: "text",
                    value: "",
                },
            });
            inputs.push({
                components: {
                    id: 0,
                    id_input: 4,
                    disabled: true,
                    validate: "required",
                    name: "code",
                    placeholder: "Tax Inovice Number",
                    title: "Tax Inovice Number",
                    type: "text",
                    value: "",
                },
            });
            inputs.push({
                components: {
                    id: 0,
                    id_input: 5,
                    disabled: true,
                    validate: "required",
                    name: "suplier_code",
                    placeholder: "Customer/Supplier Code",
                    title: "Customer/Supplier Code",
                    type: "input",
                    value: "",
                },
            });
            inputs.push({
                components: {
                    id: 0,
                    id_input: 6,
                    disabled: true,
                    validate: "required",
                    name: "suplier_name",
                    placeholder: "Customer/Supplier Name",
                    title: "Customer/Supplier Name",
                    type: "input",
                    value: "",
                },
            });

            return inputs
        },
        getInputs(data = null, i = 0, no = 0) {
            let periode = ""
            let dnCode = ""
            let customerCode = ""
            let customerName = ""
            let taxNumber = ""
            // let exportDate = ""
            // let fileLink = ""
            // let fileName = ""

            const inputs = []
            if (data) {
                periode = moment(data.PostingDate).format('YYYY-MM-DD');
                dnCode = data.Code
                customerCode = data.SupplierCode
                customerName = data.SupplierName
                taxNumber = data.TaxNumber
                // exportDate = data.ExportDate
                // fileLink = data.FilePath
                // fileName = data.fileName
            }

            inputs.push({
                components: {
                    id: i,
                    id_input: 0,
                    disabled: true,
                    validate: "required",
                    name: "no",
                    width: 40,
                    text_align: "text-center",
                    placeholder: "0",
                    title: "No",
                    type: "text",
                    value: "" + no,
                },
            });

            inputs.push({
                components: {
                    id: i,
                    id_input: 1,
                    disabled: false,
                    validate: "required",
                    name: "checkbox",
                    placeholder: "Checkbox",
                    title: "checkbox",
                    head: "title", //checkbox/title
                    type: "checkbox",
                    value: false,
                },
            });
            inputs.push({
                components: {
                    id: i,
                    id_input: 2,
                    disabled: true,
                    validate: "required",
                    name: "tax_invoice_date",
                    placeholder: "Tax Invoice Date",
                    title: "Tax Invoice Date",
                    type: "text",
                    value: periode,
                },
            })
            inputs.push({
                components: {
                    id: i,
                    id_input: 3,
                    disabled: true,
                    validate: "required",
                    name: "tax_invoice_date",
                    placeholder: "DN Code",
                    title: "DN Code",
                    type: "text",
                    value: dnCode,
                },
            });
            inputs.push({
                components: {
                    id: i,
                    id_input: 4,
                    disabled: true,
                    validate: "required",
                    name: "tax_invoice_date",
                    placeholder: "Tax Invoice Number",
                    title: "Tax Invoice Number",
                    type: "text",
                    value: taxNumber,
                },
            });
            inputs.push({
                components: {
                    id: i,
                    id_input: 5,
                    disabled: true,
                    validate: "required",
                    name: "customer_code",
                    placeholder: "Customer/Supplier Code",
                    title: "Customer/Supplier Code",
                    type: "text",
                    value: customerCode,
                },
            });
            inputs.push({
                components: {
                    id: i,
                    id_input: 6,
                    disabled: true,
                    validate: "required",
                    name: "customer_name",
                    placeholder: "Customer/Supplier Name",
                    title: "Customer/Supplier Name",
                    type: "text",
                    value: customerName,
                },
            });

            // inputs.push({
            //     components: {
            //         id: i,
            //         id_input: 6,
            //         disabled: true,
            //         validate: "required",
            //         name: "export_date",
            //         placeholder: "Last Export Date",
            //         title: "Last Export Date",
            //         type: "text",
            //         value: exportDate,
            //     },
            // });
            // inputs.push({
            //     components: {
            //         id: i,
            //         id_input: 7,
            //         disabled: true,
            //         validate: "required",
            //         name: "file",
            //         placeholder: "File",
            //         title: "File",
            //         target: "_blank",
            //         href: fileLink,
            //         handler_event: true,
            //         type: "text-link",
            //         value: fileName,
            //     },
            // });

            return inputs
        },
        forminputyear() {
            return {
                disabled: false,
                validate: "required",
                name: "year",
                placeholder: "Select Year",
                value: "",
                type: "date",
                min_view: "year",
                format: 'yyyy',
                track_by: "ID",
                onSelectEvent: (selected) => {
                    console.log(selected);
                },
            };
        },
        forminputmonth() {
            return {
                disabled: false,
                validate: "required",
                name: "month",
                placeholder: "Select Month",
                value: "",
                type: "date",
                min_view: "month",
                format: 'MM',
                onSelectEvent: (selected) => {
                    console.log(selected);
                },
            };
        },
        loadData(data) {
            this.formCompany.inputs.value = data.company
            this.formCompany.inputs.disabled = true
            // this.tempSupplier = [{ ID: 0, text: "All" }, ...dataSelect]
            var dataSelect = data.supplier.map(s => ({ ID: s.ID, text: `${s.Code} - ${s.Name}` }));
            this.formSupplier.inputs.option = [{ ID: 0, text: "All" }, ...dataSelect]
        },
        setStartEnd() {
            let valStart =
                this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        async handleView() {
            if (this.formMonth.inputs.value && this.formYear.inputs.value) {
                const dataView = await this.getView()

                this.table.total = dataView.total_record
                this.table.totalPage = dataView.total_page
                this.table.totalSearch = dataView.total_record_search;
                this.table.length = dataView.total_record_per_page
                this.setStartEnd()

                let no = this.table.start
                const dataInvoice = dataView.dataInvoice.map((item, index) => (
                    {
                        id: index,
                        id_invoice: item.ID,
                        action: false,
                        inputs: this.getInputs(item, index, no++)
                    }
                ))

                this.itemRefs = [...dataInvoice]
            }
        },
        async handleExport() {
            try {
                this.$vs.loading();
                var month = "00"
                var year = "2022"
                if (this.formMonth.inputs.value) {
                    month = this.formMonth.inputs.value.getMonth()
                }
                if (this.formYear.inputs.value) {
                    year = this.formYear.inputs.value.getFullYear()
                }

                const data_id = this.itemRefs
                    .filter(dt => dt.inputs[1].components.value)
                    .map(dt => dt.id_invoice);

                const params = {
                    operating_unit_id: 0,
                    territory_id: this.formSupplier.inputs.value.ID,
                    supplier_ids: this.formSupplier.inputs.value.map(s => s.ID),
                    tax_periode: year + "-" + ((month + 1) < 10 ? "0" + (month + 1) : "" + (month + 1)),
                    invoice_type: 3,// Invoice Type DN Trade
                    tax_invoice_type: "All",
                    type_exp: "csv",
                    data_id,
                    is_all: false
                }
                const result = await this.$http
                    .post(`api/v1/master/tax-invoice/export`, params)

                if (result.code != 200) {
                    throw new Error(result.message);
                }
                this.$vs.notify({
                    color: "success",
                    title: "Success",
                    text: result.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                this.handleView();
            } catch (error) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: error,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
            } finally {
                this.$vs.loading.close();
            }
        },
        async getData() {
            this.$vs.loading();
            try {
                const params = {
                    type: 1,
                }
                const response = await this.$http
                    .get(`api/v1/master/tax-invoice`, { params })

                if (response.code !== 200) {
                    throw new Error(response.message)
                }

                return response.data
            } catch (error) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: error,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
            } finally {
                this.$vs.loading.close();
            }
        },
        async getView() {
            try {
                this.$vs.loading();

                let month = "00"
                let year = "2022"
                if (this.formMonth.inputs.value) {
                    month = this.formMonth.inputs.value.getMonth()
                }
                if (this.formYear.inputs.value) {
                    year = this.formYear.inputs.value.getFullYear()
                }


                const params = {
                    length: this.table.length,
                    page: this.table.page,
                    search: this.table.search,
                    order: this.table.order,
                    sort: this.table.sort,
                    supplier_ids: this.formSupplier.inputs.value.map(s => s.ID),
                    invoice_type: 3, // Invoice Type DN Trade
                    tax_periode: year + "-" + ((month + 1) < 10 ? "0" + (month + 1) : "" + (month + 1)),

                };

                const response = await this.$http
                    .get(`api/v1/master/tax-invoice/view-invoice-export`, { params })

                if (response.code != 200) {
                    throw new Error(response.message)
                }

                return response.data
            } catch (error) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: error,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
            } finally {
                this.$vs.loading.close();
            }
        }
    }
}

</script>